import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

// Import all images
const PRODUCTS = [
    { 
        id: 'p1', 
        name: 'Dairy, Bread & Eggs', 
        image: require('./assets/images/dairy-bread.png'), 
        baseCategory: 'Dairy, Bread & Eggs'
    },
    { 
        id: 'p2', 
        name: 'Atta & Rice', 
        image: require('./assets/images/atta-rice.png'), 
        baseCategory: 'Atta, Rice'
    },
    { 
        id: 'p3', 
        name: 'Cooking Oil & Ghee', 
        image: require('./assets/images/oil-ghee.png'), 
        baseCategory: 'Oil, Ghee & Dal'
    },
    { 
        id: 'p4', 
        name: 'Masala & Dry Fruits', 
        image: require('./assets/images/masala-dryfruit.png'), 
        baseCategory: 'Masala & DryFruits'
    },
    { 
        id: 'p5', 
        name: 'Salt, Sugar & Jaggery', 
        image: require('./assets/images/salt-sugar.png'), 
        baseCategory: 'Salt, Sugar & Jaggery'
    },
    { 
        id: 'p6', 
        name: 'Breakfast & Ketchup', 
        image: require('./assets/images/cereals.png'), 
        baseCategory: 'Breakfast & Ketchup'
    },
    { 
        id: 'p7', 
        name: 'Tea & Coffee', 
        image: require('./assets/images/tea-coffee.png'), 
        baseCategory: 'Tea & Coffee'
    },
    { 
        id: 'p8', 
        name: 'Noodles & Pasta', 
        image: require('./assets/images/noodle-pasta.png'), 
        baseCategory: 'Noodles & Pasta'
    },
    { 
        id: 'p9', 
        name: 'Papad & Pickles', 
        image: require('./assets/images/papad-pickle.png'), 
        baseCategory: 'Papads, Pickles'
    },
    { 
        id: 'p10', 
        name: 'Frozen Food', 
        image: require('./assets/images/frozen-food.png'), 
        baseCategory: 'Frozen Food'
    },
    { 
        id: 'p11', 
        name: 'Chocolates & Toffees', 
        image: require('./assets/images/choclates-sweets.png'), 
        baseCategory: 'Chocolates, Pastries & Candies'
    },
    { 
        id: 'p12', 
        name: 'Cold Drinks & Juices', 
        image: require('./assets/images/colddrink-juice.png'), 
        baseCategory: 'Cold Drinks & Juices'
    },
    { 
        id: 'p13', 
        name: 'Chips & Namkeen', 
        image: require('./assets/images/snacks-namkeen.png'), 
        baseCategory: 'Chips & Namkeeen'
    },
    { 
        id: 'p14', 
        name: 'Biscuits & Cookies', 
        image: require('./assets/images/biscuit-cakes.png'), 
        baseCategory: 'Biscuits & Cookies'
    },
    { 
        id: 'p15', 
        name: 'Bath & Body', 
        image: require('./assets/images/bath-body.png'), 
        baseCategory: 'Bath and Body '
    },
    { 
        id: 'p16', 
        name: 'Skin Care', 
        image: require('./assets/images/skin-care.png'), 
        baseCategory: 'Skincare'
    },
    { 
        id: 'p17', 
        name: 'Baby Care', 
        image: require('./assets/images/baby-care.png'), 
        baseCategory: 'Baby Care'
    },
    { 
        id: 'p18', 
        name: 'Shampoo & Hair Oil', 
        image: require('./assets/images/hair-care.png'), 
        baseCategory: 'Shampoo & Hair Oil'
    },
    { 
        id: 'p19', 
        name: 'Perfumes & Grooming', 
        image: require('./assets/images/grooming.png'), 
        baseCategory: 'Fragrances & Grooming'
    },
    { 
        id: 'p20', 
        name: 'Feminine Hygiene', 
        image: require('./assets/images/feminine-hygiene.png'), 
        baseCategory: 'Feminine Hygiene'
    },
    { 
        id: 'p21', 
        name: 'Cleaning Essentials', 
        image: require('./assets/images/cleaning.png'), 
        baseCategory: 'Cleaning Essentials'
    },
    { 
        id: 'p22', 
        name: 'Pet Care', 
        image: require('./assets/images/pet-supplies.png'), 
        baseCategory: 'Pet Care'
    }
];

const ProductTile = ({ id, name, image, onProductClick }) => {

    return (
        <div className="p-4 flex flex-col items-center space-y-2 w-32 md:w-36 lg:w-40">
            <div onClick={() => onProductClick(name)} className='cursor-pointer'>
                <div className="w-28 h-20 md:w-32 md:h-24 lg:w-40 lg:h-32 rounded-lg overflow-hidden place-items-center">
                    <img
                        src={image}
                        alt={name}
                        className="w-full h-full object-contain"
                    />
                </div>
                <p className="text-sm font-semibold text-center whitespace-normal break-words w-full mt-2">
                    {name}
                </p>
            </div>
        </div>
    );
};

const ProductsPage = ({category}) => {
    const navigate = useNavigate();
    const [displayProducts, setDisplayProducts] = useState(
        category 
            ? PRODUCTS.filter(p => p.baseCategory === category)
            : PRODUCTS
    );

    const handleProductClick = (productName) => {
        // Find the product's base category from local data
        const product = PRODUCTS.find(p => p.name === productName);
        if (product?.baseCategory) {
            navigate(`/category/${encodeURIComponent(product.baseCategory)}`);
        }
    };

    useEffect(() => {
        // Update displayed products when category prop changes
        setDisplayProducts(
            category 
                ? PRODUCTS.filter(p => p.baseCategory === category) 
                : PRODUCTS
        );
    }, [category]);

    return (
        <div className="flex justify-center w-full py-4">
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 w-11/12 md:w-10/12 lg:w-9/12 place-items-center">
                {displayProducts.map((product) => (
                    <div 
                        key={product.id}
                        className="w-32 h-34 md:w-32 md:h-34 lg:w-40 lg:h-38 p-4 shadow-md rounded-lg flex flex-col justify-between items-center"
                    >
                        <ProductTile 
                            {...product}
                            onProductClick={handleProductClick}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProductsPage;