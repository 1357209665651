import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import axios from 'axios';
import ContactUs from './ContactUs';
import { translations } from './LanguageSwitcher';
import ResponsiveAppBar from './ResponsiveAppBar';
import { CartProvider } from './CartContext';
import ProductsPage from './Products';
import Join from './JoinUs';
import Careers from './Careers';
import AboutUs from './page_components/AboutUs';
import CreateOrder from './CreateOrder';
import CategoryPage from './CategoryPage';
import ProductList from './ProductList';


const PageLayout = ({ children }) => (
  <div className="w-full flex justify-center">
    <div className="w-full max-w-[1920px] px-4 md:px-6 lg:px-8">
      {children}
    </div>
  </div>
);

const Home = () => (
  <PageLayout>
    {/* <Loader /> */}
    <ProductsPage/>
  </PageLayout>
);

const Order = ({ t, pincode, setPincode, handlePincodeSubmit }) => (
  <PageLayout>
    <div className="order-form flex flex-col items-center px-4 md:px-6 lg:px-8">
      <p className="text-center text-base md:text-lg lg:text-xl mb-4">{t.enterPincode}</p>
      <form onSubmit={handlePincodeSubmit} className="w-full max-w-sm md:max-w-md lg:max-w-lg">
        <input
          type="text"
          placeholder={t.pincodePrompt}
          value={pincode}
          onChange={(e) => setPincode(e.target.value)}
          className="pincode-input w-full p-2 md:p-3 text-sm md:text-base"
        />
        <button type="submit" className="submit-button w-full mt-2 md:mt-4 p-2 md:p-3">
          {t.submit}
        </button>
      </form>
    </div>
  </PageLayout>
);

const About = ({t}) => (
  <PageLayout>
    <AboutUs t={t}/>
  </PageLayout>
);

function App() {
  const [pincode, setPincode] = useState('');

  const handlePincodeSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`https://pincode-nextdoordukan.azurewebsites.net/api/pincode`, { pincode });
      alert(`Coming soon to your Pincode: ${pincode}`);
      console.log(response.data);
    } catch (err) {
      console.error('Error saving pincode:', err);
    }
  };
  const language = 'English';
  const t = translations['English'];

  return (
    <CartProvider>
      <Router>
        <div className="w-full pt-4 md:pt-6 lg:pt-10 min-h-screen bg-[#EFCDBC]">
          <div className="w-full flex flex-col items-center">
            <div className="w-full max-w-[1920px]">
              <header className="w-full flex justify-center px-4 md:px-6 lg:px-8">
                <ResponsiveAppBar />
              </header>
              {/* <Logo /> */}
              <main className="w-full">
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/about" element={<About t={t} />} />
                  <Route path="/join" element={<Join />} />
                  <Route 
                    path="/contact" 
                    element={
                      <PageLayout>
                        <ContactUs language={language} />
                      </PageLayout>
                    } 
                  />
                  <Route path="/careers" element={<Careers />} />
                  <Route 
                    path="/order" 
                    element={
                      <Order 
                        t={t} 
                        pincode={pincode} 
                        setPincode={setPincode} 
                        handlePincodeSubmit={handlePincodeSubmit} 
                      />
                    } 
                  />
                  <Route 
                    path="/order/create/utt-pos-work-conc-2025" 
                    element={
                      <CreateOrder />
                    }
                  />
                  <Route path="/category/:categoryName" element={<CategoryPage />} />
                  <Route path="/product" element={<ProductList />} />
                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
              </main>
            </div>
          </div>
        </div>
      </Router>
    </CartProvider>
  );
}

export default App;