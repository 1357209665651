// ProductForm.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { X } from 'lucide-react';

const ProductForm = ({ fetchProducts, editingProduct, setEditingProduct }) => {
  const [formData, setFormData] = useState({
    Category: '',
    ProductName: '',
    Weight: '',
    MRP: '',
    BaseCategory: '',
    ImageURL: '',
    price: '',
    keywords: []
  });

  const [keywordInput, setKeywordInput] = useState('');

  useEffect(() => {
    if (editingProduct) {
      setFormData({
        ...editingProduct,
        keywords: editingProduct.keywords || [], // Handle case where keywords might not exist
      });
    } else {
      setFormData({
        Category: '',
        ProductName: '',
        Weight: '',
        MRP: '',
        BaseCategory: '',
        ImageURL: '',
        price: '',
        keywords: []
      });
    }
  }, [editingProduct]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const apiUrl = process.env.REACT_APP_AUTH_API_URL;
      if (editingProduct) {
        await axios.put(
          `${apiUrl}/api/product/${editingProduct._id}`,
          formData
        );
      } else {
        await axios.post(`${apiUrl}/api/product/`, formData);
      }
      setFormData({
        Category: '',
        ProductName: '',
        Weight: '',
        MRP: '',
        BaseCategory: '',
        ImageURL: '',
        price: '',
        keywords: []
      });
      setEditingProduct(null);
      fetchProducts();
    } catch (err) {
      console.error(err);
    }
  };

  const handleKeywordKeyDown = (e) => {
    if (e.key === 'Enter' && keywordInput.trim()) {
      e.preventDefault();
      if (!formData.keywords.includes(keywordInput.trim())) {
        setFormData({
          ...formData,
          keywords: [...formData.keywords, keywordInput.trim()]
        });
        setKeywordInput('');
      }
    } else if (e.key === 'Backspace' && !keywordInput && formData.keywords.length > 0) {
      setFormData({
        ...formData,
        keywords: formData.keywords.slice(0, -1)
      });
    }
  };

  const removeKeyword = (indexToRemove) => {
    setFormData({
      ...formData,
      keywords: formData.keywords.filter((_, index) => index !== indexToRemove)
    });
  };

  return (
    <form onSubmit={handleSubmit} className="bg-white p-4 rounded shadow mb-4">
      <h2 className="text-lg font-semibold mb-4">
        {editingProduct ? 'Edit Product' : 'Add New Product'}
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="space-y-2">
          <input
            type="text"
            placeholder="Product Name"
            className="w-full p-2 border rounded"
            value={formData.ProductName}
            onChange={(e) => setFormData({ ...formData, ProductName: e.target.value })}
            required
          />
          <input
            type="text"
            placeholder="Category"
            className="w-full p-2 border rounded"
            value={formData.Category}
            onChange={(e) => setFormData({ ...formData, Category: e.target.value })}
            required
          />
          <input
            type="text"
            placeholder="Base Category"
            className="w-full p-2 border rounded"
            value={formData.BaseCategory}
            onChange={(e) => setFormData({ ...formData, BaseCategory: e.target.value })}
            required
          />
          <input
            type="number"
            placeholder="Price"
            className="w-full p-2 border rounded"
            value={formData.price}
            onChange={(e) => setFormData({ ...formData, price: e.target.value })}
            required
          />
        </div>
        <div className="space-y-2">
          <input
            type="number"
            placeholder="MRP"
            className="w-full p-2 border rounded"
            value={formData.MRP}
            onChange={(e) => setFormData({ ...formData, MRP: e.target.value })}
            required
          />
          <input
            type="text"
            placeholder="Weight"
            className="w-full p-2 border rounded"
            value={formData.Weight}
            onChange={(e) => setFormData({ ...formData, Weight: e.target.value })}
          />
          <input
            type="url"
            placeholder="Image URL"
            className="w-full p-2 border rounded"
            value={formData.ImageURL}
            onChange={(e) => setFormData({ ...formData, ImageURL: e.target.value })}
          />
        </div>
      </div>
      <div className="mt-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Product Keywords
        </label>
        <div className="min-h-12 p-2 border rounded focus-within:ring-2 focus-within:ring-green-500 focus-within:border-green-500">
          <div className="flex flex-wrap gap-2">
            {formData.keywords.map((keyword, index) => (
              <span
                key={index}
                className="inline-flex items-center px-2 py-1 rounded bg-green-100 text-green-800"
              >
                {keyword}
                <button
                  type="button"
                  onClick={() => removeKeyword(index)}
                  className="ml-1 hover:text-green-600 focus:outline-none"
                >
                  <X size={14} />
                </button>
              </span>
            ))}
            <input
              type="text"
              value={keywordInput}
              onChange={(e) => setKeywordInput(e.target.value)}
              onKeyDown={handleKeywordKeyDown}
              className="flex-1 min-w-32 outline-none bg-transparent"
              placeholder={formData.keywords.length === 0 ? "Enter keywords and press Enter..." : ""}
            />
          </div>
        </div>
        <p className="mt-1 text-sm text-gray-500">
          Press Enter to add a keyword. Press Backspace to remove the last keyword.
        </p>
      </div>
      <button
        type="submit"
        className="mt-4 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
      >
        {editingProduct ? 'Update Product' : 'Add Product'}
      </button>
      {editingProduct && (
        <button
          type="button"
          onClick={() => setEditingProduct(null)}
          className="mt-4 ml-2 px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
        >
          Cancel
        </button>
      )}
    </form>
  );
};

export default ProductForm;