import React from 'react'

const Logo = () => (
    <div className="flex items-center gap-x-2">
        <img
            src="/Logo.png"
            alt="NextDoor Dukan Logo"
            className="w-20 h-20"
        />
        <div className="flex flex-col items-center">
            <h1 className="text-2xl text-[#501F21] font-carter-one text-center">
                NextDoor{' '}
                <span className="font-eczar font-semibold">
                    दुकान
                </span>
            </h1>
            <p className="text-sm text-[#000000] font-carter-one text-center mt-1">
                Empowering Kirana Stores
            </p>
        </div>
    </div>
);

export default Logo;