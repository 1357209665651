import React, { useState} from 'react';
import {X, Trash2 } from 'lucide-react';
import { GiShoppingCart } from "react-icons/gi";
import Logo from './Logo';
import { useCart } from './CartContext';

const CartSidebar = ({ isOpen, onClose }) => {
  const { state, dispatch } = useCart();
  const userId = 1;
  const userCart = state.carts[userId] || { items: [] };

  const totalAmount = state.items.reduce((total, item) => total + (item.price * item.quantity), 0);

  const removeItem = (id) => {
    dispatch({ type: 'REMOVE_FROM_CART', payload: { userId, id } });
  };

  const updateQuantity = (id, quantity) => {
    if (quantity === 0) {
      removeItem(id);
    } else {
      dispatch({ type: 'UPDATE_QUANTITY', payload: { userId, id, quantity } });
    }
  };

  return (
    <div 
      className={`fixed inset-y-0 right-0 z-50 w-96 bg-white shadow-lg transform transition-transform duration-300 ease-in-out ${
        isOpen ? 'translate-x-0' : 'translate-x-full'
      }`}
    >
      <div className="flex flex-col h-full">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-xl font-semibold">Shopping Cart ({userCart.items.length})</h2>
          <button 
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        <div className="flex-1 overflow-y-auto p-4 space-y-4">
          {userCart.items.map((item) => (
            <div key={item.id} className="flex items-center space-x-4 border-b pb-4">
              <img src={item.image} alt={item.name} className="w-20 h-20 object-cover rounded" />
              <div className="flex-1">
                <h3 className="font-medium">{item.name}</h3>
                <p className="text-orange-500">₹{item.price}</p>
                <div className="flex items-center space-x-2 mt-2">
                  <button 
                    onClick={() => updateQuantity(item.id, item.quantity - 1)}
                    className="px-2 py-1 border rounded"
                  >
                    -
                  </button>
                  <span>{item.quantity}</span>
                  <button 
                    onClick={() => updateQuantity(item.id, item.quantity + 1)}
                    className="px-2 py-1 border rounded"
                  >
                    +
                  </button>
                  <button 
                    onClick={() => removeItem(item.id)}
                    className="ml-4 p-1 text-red-500 hover:bg-red-50 rounded"
                  >
                    <Trash2 className="w-5 h-5" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="border-t p-4 space-y-4">
          <div className="flex justify-between items-center">
            <span className="font-semibold">Total:</span>
            <span className="font-semibold text-orange-500">₹{totalAmount}</span>
          </div>
          <button 
            className="w-full bg-orange-500 text-white py-3 rounded-lg hover:bg-orange-600 transition-colors"
            onClick={() => {/* Implement checkout */}}
          >
            Checkout
          </button>
        </div>
      </div>
    </div>
  );
};

function ResponsiveAppBar() {
  const [isCartOpen, setIsCartOpen] = useState(false);
  const { cartCount } = useCart();

  const CartIcon = () => (
    <div className="relative">
      <button 
        onClick={() => setIsCartOpen(true)}
        className="focus:outline-none"
      >
        <GiShoppingCart size={40} className="text-[#000000] hover:text-[#000000] transition-colors " />
        {cartCount > 0 && (
          <div className="absolute -top-2 -right-2 bg-orange-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
            {cartCount}
          </div>
        )}
      </button>
    </div>
  );

  return (
    <>
      <div className="w-full flex justify-center">
        <nav className="relative w-full max-w-[1920px]">
          <div className="hidden md:flex justify-center items-center w-full px-8">
            <div className="flex-grow flex justify-center">
              <Logo/>
            </div>
            <CartIcon/>
          </div>

          {/* Mobile Navigation */}
          <div className="md:hidden">
            <div className="flex justify-between items-center px-4">
              <div className="flex-grow flex justify-center">
                <Logo/>
              </div>
              <CartIcon/>
            </div>
          </div>
        </nav>
      </div>

      {/* Cart Sidebar */}
      <CartSidebar 
        isOpen={isCartOpen} 
        onClose={() => setIsCartOpen(false)} 
      />
    </>
  );
}

export default ResponsiveAppBar;