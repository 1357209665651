import React, { createContext, useContext, useReducer } from 'react';

const CartContext = createContext();

const cartReducer = (state, action) => {
  const { userId } = action.payload || {};
  const userCart = state.carts[userId] || { items: [] };

  switch (action.type) {
    case 'ADD_TO_CART': {
      const existingItem = userCart.items.find(item => item.id === action.payload.item.id);
      
      const updatedItems = existingItem
        ? userCart.items.map(item =>
            item.id === action.payload.item.id ? { ...item, quantity: item.quantity + 1 } : item
          )
        : [...userCart.items, { ...action.payload.item, quantity: 1 }];

      return {
        ...state,
        carts: {
          ...state.carts,
          [userId]: { ...userCart, items: updatedItems },
        },
      };
    }

    case 'REMOVE_FROM_CART': {
      const updatedItems = userCart.items.filter(item => item.id !== action.payload.id);

      return {
        ...state,
        carts: {
          ...state.carts,
          [userId]: { ...userCart, items: updatedItems },
        },
      };
    }

    case 'UPDATE_QUANTITY': {
      const updatedItems = userCart.items.map(item =>
        item.id === action.payload.id ? { ...item, quantity: action.payload.quantity } : item
      );

      return {
        ...state,
        carts: {
          ...state.carts,
          [userId]: { ...userCart, items: updatedItems },
        },
      };
    }

    default:
      return state;
  }
};

export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, { carts: {}, items: [] });

  return (
    <CartContext.Provider value={{ state, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};
export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};