import React, { useState } from 'react';
import axios from 'axios';

const CreateOrder = () => {
  const [formData, setFormData] = useState({
    customerName: '',
    shopName: '',
    dropLocation: '',
    pickupLocation: '',
    orderDetails: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Replace with your actual API endpoint
      const response = await axios.post('https://auth-rider-app-a3abdxgvg3hxc4dc.southindia-01.azurewebsites.net/api/order', formData);
      alert('Order created successfully!');
      // Clear form after successful submission
      setFormData({
        customerName: '',
        shopName: '',
        dropLocation: '',
        pickupLocation: '',
        orderDetails: ''
      });
    } catch (error) {
      console.error('Error creating order:', error);
      alert('Failed to create order. Please try again.');
    }
  };

  return (
    <div className="w-full max-w-2xl mx-auto p-6">
      <h2 className="text-2xl font-bold mb-6 text-center">Create New Order</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium mb-1">Customer Name</label>
          <input
            type="text"
            name="customerName"
            value={formData.customerName}
            onChange={handleChange}
            required
            className="w-full p-2 border rounded-md"
          />
        </div>
        
        <div>
          <label className="block text-sm font-medium mb-1">Shop Name</label>
          <input
            type="text"
            name="shopName"
            value={formData.shopName}
            onChange={handleChange}
            required
            className="w-full p-2 border rounded-md"
          />
        </div>
        
        <div>
          <label className="block text-sm font-medium mb-1">Customer Address</label>
          <textarea
            name="dropLocation"
            value={formData.dropLocation}
            onChange={handleChange}
            required
            className="w-full p-2 border rounded-md"
            rows="3"
          />
        </div>
        
        <div>
          <label className="block text-sm font-medium mb-1">Shop Address</label>
          <textarea
            name="pickupLocation"
            value={formData.pickupLocation}
            onChange={handleChange}
            required
            className="w-full p-2 border rounded-md"
            rows="3"
          />
        </div>
        
        <div>
          <label className="block text-sm font-medium mb-1">Order Description</label>
          <textarea
            name="orderDetails"
            value={formData.orderDetails}
            onChange={handleChange}
            required
            className="w-full p-2 border rounded-md"
            rows="4"
          />
        </div>
        
        <button
          type="submit"
          className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition-colors"
        >
          Create Order
        </button>
      </form>
    </div>
  );
};

export default CreateOrder;