import React, { useEffect, useState } from 'react';
import { Plus, Minus } from 'lucide-react';
import { useCart } from './CartContext';
import { useParams } from 'react-router-dom';

const CategoryPage = () => {
    const [categoryProducts, setCategoryProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { categoryName } = useParams();
    const { state, dispatch } = useCart();
    
    // In a real app, get this from authentication context
    const userId = 1; 

    const addToCart = (product) => {
        dispatch({
            type: 'ADD_TO_CART',
            payload: { 
                userId,
                item: { 
                    id: product._id, 
                    name: product['Product Name'], 
                    image: product.ImageURL, 
                    price: product.price 
                }
            }
        });
    };

    const updateQuantity = (productId, newQuantity) => {
        if (newQuantity === 0) {
            dispatch({
                type: 'REMOVE_FROM_CART',
                payload: { userId, id: productId }
            });
        } else {
            dispatch({
                type: 'UPDATE_QUANTITY',
                payload: { userId, id: productId, quantity: newQuantity }
            });
        }
    };

    useEffect(() => {
        const fetchCategoryProducts = async () => {
            try {
                console.log('process.env:', process.env);
                const apiUrl = process.env.REACT_APP_AUTH_API_URL;
                console.log('apiUrl:', apiUrl);
                const response = await fetch(`${apiUrl}/api/product?baseCategory=${encodeURIComponent(categoryName)}`);
                console.log('response:', response);
                if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
                
                const data = await response.json();
                setCategoryProducts(data || []);
            } catch (error) {
                console.error('Fetch error:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchCategoryProducts();
    }, [categoryName]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Products in {decodeURIComponent(categoryName)}</h1>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
                {categoryProducts.map(product => {
                    const cartItem = state.carts[userId]?.items?.find(item => item.id === product._id) || null;
                    const quantity = cartItem?.quantity || 0;

                    return (
                        <div key={product._id} className="border border-[#B89977] p-4 rounded-lg flex flex-col justify-between h-[280px]">
                            <div className = "flex flex-col items-center justify-center text-center">
                                <img 
                                    src={product.ImageURL} 
                                    alt={product.ProductName}
                                    className="w-34 h-34 object-cover mb-2"
                                    style={{ maxWidth: '100px', maxHeight: '100px' }}
                                />
                                <h2 className="font-bold text-base text-[#4A3324] h-[50px] text-center ">{product.ProductName}</h2>
                                <p className="font-bold text-[#7A6250]">{product.Weight}</p>
                                <div className="text-stone-950">
                                    <p>
                                        <span className="text-stone-950 font-bold">₹{product.price}</span> 
                                        <span className="line-through text-[#9A8271] ml-2">{product.MRP}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="mt-auto flex justify-center">
                                {quantity === 0 ? (
                                    <button 
                                        onClick={() => addToCart(product)}
                                        className='bg-[#5B3627] border-[#7A4D3A] rounded-md py-2 px-4 border-2 text-[#FFFFFF] hover:bg-[#7A4D3A] transition-colors'
                                    >
                                        Add to Cart
                                    </button>
                                ) : (
                                    <div className='flex items-center justify-between border-2 border-[#501F21] rounded-md px-2 py-1 w-[150px]'>
                                        <button 
                                            onClick={() => updateQuantity(product._id, quantity - 1)}
                                            className='p-2 hover:bg-[#7A6250] rounded-md flex items-center justify-center'
                                        >
                                            <Minus className="w-5 h-5 text-[#5B4636]" />
                                        </button>
                                        <span className='font-bold text-[#2E1E12] w-6 text-center'>{quantity}</span>
                                        <button 
                                            onClick={() => updateQuantity(product._id, quantity + 1)}
                                            className='p-2 hover:bg-[#7A6250] rounded-md flex items-center justify-center'
                                        >
                                            <Plus className="w-5 h-5 text-[#5B4636]" />
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default CategoryPage;