// ProductList.js
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import ProductForm from './ProductForm';

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [baseCategories, setBaseCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [editingProduct, setEditingProduct] = useState(null);
  const apiUrl = process.env.REACT_APP_AUTH_API_URL;

  const fetchProducts = useCallback(async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/product`);
      setProducts(response.data);

      const uniqueCategories = [
        ...new Set(response.data.map((product) => product.BaseCategory))
      ];
      setBaseCategories(uniqueCategories);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  }, [apiUrl]); // Include apiUrl in dependencies

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${apiUrl}/api/product/${id}`);
      fetchProducts();
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Inventory Management</h1>

      {/* Product Form for Adding/Editing Products */}
      <ProductForm
        fetchProducts={fetchProducts}
        editingProduct={editingProduct}
        setEditingProduct={setEditingProduct}
      />

      {/* Base Category List */}
      <div className="mt-8">
        <h2 className="text-xl font-semibold mb-4">Base Categories</h2>
        <div className="flex flex-wrap gap-2">
          {baseCategories.map((category, index) => (
            <button
              key={index}
              onClick={() => setSelectedCategory(category)}
              className={`px-4 py-2 rounded-md ${selectedCategory === category ? 'bg-blue-600 text-white' : 'bg-gray-200'
                }`}
            >
              {category}
            </button>
          ))}
        </div>
      </div>

      {/* Products List (Filtered by Selected Category) */}
      {selectedCategory && (
        <div className="mt-8">
          <h2 className="text-xl font-semibold mb-4">
            Products in "{selectedCategory}"
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {products
              .filter((product) => product.BaseCategory === selectedCategory)
              .map((product) => (
                <div
                  key={product._id}
                  className="border p-4 rounded-lg shadow-sm cursor-pointer"
                  onClick={() => setEditingProduct(product)}
                >
                  {product.ImageURL && (
                    <img
                      src={product.ImageURL}
                      alt={product.ProductName}
                      className="w-24 h-32 object-cover mb-2"
                      style={{ maxWidth: '150px', maxHeight: '200px' }}
                    />
                  )}
                  <h3 className="font-bold text-lg">{product.ProductName}</h3>
                  <p>Category: {product.Category}</p>
                  <p>MRP: ₹{product.MRP}</p>
                  <p>Weight: {product.Weight}</p>
                  <div className="mt-2 flex gap-2">
                    <button
                      className="px-3 py-1 bg-blue-500 text-white rounded"
                    >
                      Edit
                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent clicking product when deleting
                        handleDelete(product._id);
                      }}
                      className="px-3 py-1 bg-red-500 text-white rounded"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductList;